import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
export const Twitter = () => {
  const handleClick = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "twitter_link",
        event_label: "Konto na Twitterze - Lempiank",
      });
    }
  };
  return (
    <div>
      <OutboundLink
        href="https://twitter.com/lempiank"
        target="_blank"
        rel="noreferrer nofollow"
        onClick={handleClick}
        className="flex h-8 w-8 cursor-pointer items-center justify-center fill-gray_icons hover:fill-post_likes_font_color dark:fill-white dark:hover:fill-post_likes_font_color"
        title="Konto na Twitterze - Lempiank"
      >
        <svg
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill=""
          className=""
          xmlns="http://www.w3.org/2000/svg"
          loading="lazy"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7711 4.58961C16.7779 4.76449 16.7802 4.93931 16.7802 5.11419C16.7802 10.4164 13.0503 16.5375 6.22975 16.5375C4.13459 16.5375 2.18638 15.8697 0.544922 14.733C0.835079 14.7648 1.12976 14.7887 1.42896 14.7887C3.16614 14.7887 4.76615 14.1446 6.03531 13.0635C4.41269 13.0397 3.04255 11.8711 2.57 10.2813C2.79686 10.329 3.03047 10.3529 3.26938 10.3529C3.60627 10.3529 3.93335 10.3052 4.24687 10.2098C2.54888 9.84417 1.26994 8.22254 1.26994 6.27496C1.26994 6.25111 1.26994 6.24311 1.26994 6.22722C1.77037 6.52134 2.34314 6.70408 2.95134 6.72793C1.95501 6.00454 1.30007 4.77243 1.30007 3.3813C1.30007 2.64996 1.48246 1.95833 1.80351 1.36214C3.63188 3.79463 6.36542 5.39247 9.44712 5.5594C9.38381 5.26528 9.35139 4.95533 9.35139 4.64531C9.35139 2.42745 11.0117 0.630859 13.0601 0.630859C14.1265 0.630859 15.0897 1.11584 15.7657 1.89487C16.6121 1.71999 17.405 1.38614 18.1224 0.925078C17.8443 1.8631 17.2572 2.64992 16.49 3.14278C17.2407 3.04738 17.9566 2.83292 18.6206 2.51494C18.1224 3.31782 17.4954 4.02521 16.7711 4.58961Z"
          />
        </svg>
      </OutboundLink>
    </div>
  );
};
