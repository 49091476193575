import { useContext } from "react";
import { AuthContext } from "../components/supabase/supabase";

export const useAuth = () => {
  const authState = useContext(AuthContext);

  return {
    user: authState?.user,
    session: authState?.session,
    loading: authState?.loading,
    error: authState?.error,
    isAuthenticated: !!authState?.user && !!authState?.session,
  };
};
