import React, { useState, useEffect } from "react";
import "../../styles/calendar.css";
import { isSameDay } from "date-fns";
import { CalendarQuery } from "../../hooks/use-calendar-events";

const daysInWeek = ["Pon", "Wto", "Śro", "Czw", "Pią", "Sob", "Nie"];
const monthsInYear = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];

const Calendar = () => {
  const calendarEvents = CalendarQuery();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [eventName, setEventName] = useState("");

  useEffect(() => {
    updateSelectedEvents(selectedDate);
  }, []);

  const handleMouseEnter = (e) => {
    const element = e.target;
    if (element.classList.contains("selected-event")) {
      element.style.borderBottom = "1px solid #fe93ae";
    }
  };

  const handleMouseLeave = (e) => {
    const element = e.target;
    if (element.classList.contains("selected-event")) {
      element.style.borderBottom = "";
    }
  };

  const updateSelectedEvents = (date) => {
    const filteredEvents = calendarEvents.filter(
      (event) =>
        new Date(event.start.dateTime).toDateString() === date.toDateString()
    );
    setSelectedEvents(filteredEvents);
    const eventName = filteredEvents.length
      ? filteredEvents[0].description.replace(/<[^>]+>/g, "")
      : "";
    setEventName(eventName);
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const firstDayOfMonth = (month, year) => {
    return new Date(year, month, 0).getDay();
  };

  const onSelectDate = (date) => {
    setSelectedDate(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        new Date().getHours(),
        0,
        0,
        0
      )
    );
    updateSelectedEvents(date);
  };

  const onPrevMonth = () => {
    setCurrentMonth(currentMonth === 0 ? 11 : currentMonth - 1);
    setCurrentYear(currentMonth === 0 ? currentYear - 1 : currentYear);
  };

  const onNextMonth = () => {
    setCurrentMonth(currentMonth === 11 ? 0 : currentMonth + 1);
    setCurrentYear(currentMonth === 11 ? currentYear + 1 : currentYear);
  };

  const renderDaysOfWeek = () => {
    return daysInWeek.map((day) => (
      <div className="text-xs" key={day}>
        {day}
      </div>
    ));
  };

  const renderCalendarDays = () => {
    const today = new Date();
    const monthDays = [];
    const numDaysInMonth = daysInMonth(currentMonth, currentYear);
    const firstDayIndex = firstDayOfMonth(currentMonth, currentYear);

    // dni poprzedniego miesiąca
    const prevMonthDays = [];
    const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const prevMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const numDaysInPrevMonth = daysInMonth(prevMonth, prevMonthYear);
    for (let i = firstDayIndex - 1; i >= 0; i--) {
      const day = numDaysInPrevMonth - i;
      const date = new Date(prevMonthYear, prevMonth, day);
      prevMonthDays.push(
        <div
          key={date.getTime()}
          className="flex h-[32px] w-[36.57px] cursor-pointer items-center justify-center rounded-lg text-xs text-[#8a8a8a] hover:border hover:border-[#fe93ae]"
          onClick={() => onSelectDate(date)}
        >
          {day}
        </div>
      );
    }

    // dni bieżącego miesiąca
    for (let i = 1; i <= numDaysInMonth; i++) {
      const date = new Date(currentYear, currentMonth, i);
      const isWeekend = date.getDay() === 6 || date.getDay() === 0; // sobota lub niedziela

      const event = calendarEvents.find(
        (e) => new Date(e.start.dateTime).toDateString() === date.toDateString()
      );
      const hasEvent = event !== undefined;

      const isToday = isSameDay(date, today);
      const isSelected = isSameDay(date, selectedDate);

      monthDays.push(
        <div
          key={date.getTime()}
          className={`relative flex h-[32px] w-[36.57px] cursor-pointer items-center justify-center rounded-lg text-xs hover:border hover:border-[#fe93ae]
      ${isSelected ? "selected-day" : ""}
      ${isWeekend ? "text-[#d10000]" : ""}
      ${isToday ? "today" : ""}
    `}
          onClick={() => onSelectDate(date)}
          aria-current={isSelected ? "true" : "false"}
        >
          <span>
            {i}
            {hasEvent && (
              <div
                className={`event-dot ${
                  event.type === "trade" ? "trade" : "work"
                }`}
              ></div>
            )}
          </span>
        </div>
      );
    }

    // dni następnego miesiąca
    const nextMonthDays = [];
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextMonthYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    const remainingDays =
      (7 - ((prevMonthDays.length + monthDays.length) % 7)) % 7;
    for (let i = 1; i <= remainingDays; i++) {
      const day = i;
      const date = new Date(nextMonthYear, nextMonth, day);
      nextMonthDays.push(
        <div
          key={date.getTime()}
          className={`flex h-[32px] w-[36.57px] cursor-pointer items-center justify-center rounded-lg text-xs text-[#8a8a8a] hover:border hover:border-[#fe93ae]`}
          onClick={() => onSelectDate(date)}
        >
          {day}
        </div>
      );
    }

    return [...prevMonthDays, ...monthDays, ...nextMonthDays];
  };

  const renderCalendarHeader = () => {
    return (
      <>
        <div className="my-4 text-center font-semibold text-categories_buttons">
          <h3 className="text-xl text-calendar_title dark:text-text_dark">
            Kalendarz wydarzeń
          </h3>
        </div>
        <div className="my-4 border-b-[1px]"></div>
        <div className="flex flex-row items-center justify-between text-post_title dark:bg-third_dark dark:text-text_dark">
          <button
            className="border-1 not:xl:focus:border-[#D8D9D8] not:xl:active:border-[#fe93ae] h-9 w-9 rounded-lg border border-[#D8D9D8] outline-none hover:border-[#fe93ae] focus:border-[#D8D9D8] active:border-[#fe93ae]"
            onClick={onPrevMonth}
          >
            {"<"}
          </button>
          <div className="flex w-[244px] justify-center text-xl font-semibold">
            {`${monthsInYear[currentMonth]} ${currentYear}`}
          </div>
          <button
            className="border-1 not:xl:focus:border-[#D8D9D8] not:xl:active:border-[#fe93ae] h-9 w-9 rounded-lg border border-[#D8D9D8] outline-none hover:border-[#fe93ae] focus:border-[#D8D9D8] active:border-[#fe93ae]"
            onClick={onNextMonth}
          >
            {">"}
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="h-[500px] w-[360px] rounded-xl bg-white font-lexend dark:bg-third_dark">
      <div className="mx-auto flex w-[325px] flex-col xl:w-[316px]">
        <div className="my-4">{renderCalendarHeader()}</div>
        <div className="mb-2 grid grid-cols-7 justify-items-center gap-4 text-sm font-medium text-post_title dark:text-text_dark">
          {renderDaysOfWeek()}
        </div>
        <div className="grid grid-cols-7 grid-rows-5 justify-items-center gap-x-4 gap-y-2 text-sm text-post_title dark:text-text_dark">
          {renderCalendarDays()}
        </div>
        <div className="my-4 border-b-[1px]"></div>

        {selectedEvents.length > 0 ? (
          <div>
            {selectedEvents.map((event) => (
              <div key={event.id}>
                <p className="grid-col-2 grid items-center justify-center text-center text-post_title dark:text-text_dark">
                  <span>
                    {selectedDate
                      .toLocaleString("pl", { weekday: "long" })
                      .charAt(0)
                      .toUpperCase() +
                      selectedDate
                        .toLocaleString("pl", { weekday: "long" })
                        .slice(1)}{" "}
                    ({selectedDate.toLocaleDateString("pl")})
                  </span>
                  <span className="text-bold mt-1 whitespace-nowrap">
                    <a
                      href={event.summary}
                      rel="noreferrer"
                      target="_blank"
                      className="selected-event"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {eventName}
                    </a>
                  </span>
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid-col-2 grid text-center text-post_title dark:text-text_dark">
            <span>
              {selectedDate
                .toLocaleString("pl", { weekday: "long" })
                .charAt(0)
                .toUpperCase() +
                selectedDate
                  .toLocaleString("pl", { weekday: "long" })
                  .slice(1)}{" "}
              ({selectedDate.toLocaleDateString("pl")})
            </span>
            <span className="text-bold">Dzisiaj mam dzień wolny 💜 </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
