import React, { createContext, useMemo } from "react";
import Calendar from "./Calendar";

export const CalendarContext = createContext(null);

export const CalendarProvider = ({ children }) => {
  const memoizedCalendar = useMemo(() => <Calendar />, []);
  return (
    <CalendarContext.Provider value={memoizedCalendar}>
      {children}
    </CalendarContext.Provider>
  );
};
