import { graphql, useStaticQuery } from "gatsby";
import { useCallback } from "react";

export const CalendarQuery = () => {
  const data = useStaticQuery(graphql`
    query MyCalendarQuery {
      allCalendar {
        nodes {
          children {
            ... on CalendarEvent {
              id
              description
              summary
              start {
                dateTime
              }
            }
          }
        }
      }
    }
  `);
  const calendarNodes = data.allCalendar.nodes[0].children;

  const memoizedCalendar = useCallback(() => {
    return calendarNodes;
  }, [calendarNodes]);

  return memoizedCalendar();
};
