import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
export const Tiktok = () => {
  const handleClick = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "tiktok_link",
        event_label: "Profil na Tiktoku - Lempiank",
      });
    }
  };
  return (
    <div>
      <OutboundLink
        href="https://www.tiktok.com/@lempiank/"
        target="_blank"
        rel="noreferrer nofollow"
        onClick={handleClick}
        className="flex h-8 w-8 cursor-pointer items-center justify-center fill-gray_icons hover:fill-post_likes_font_color dark:fill-white dark:hover:fill-post_likes_font_color"
        title="Profil na TikToku - Lempiank"
      >
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          xmlns="http://www.w3.org/2000/svg"
          className="0"
          loading="lazy"
        >
          <path d="M6.6549 16.1348C8.2124 16.1348 9.475 15.0197 9.475 13.3147V0.666016H12.8571C12.6674 2.97278 14.9605 5.20106 17.4895 5.1501V8.3304C15.3267 8.3304 13.4876 7.41589 12.8475 6.90837V13.3147C12.8475 16.1348 10.629 19.3327 6.6549 19.3327C2.68085 19.3327 0.510742 16.1348 0.510742 13.3147C0.510742 9.33436 4.72292 7.02594 7.51273 7.59015V10.8295C7.37238 10.7798 7.01046 10.7064 6.70218 10.7064C5.13174 10.6485 3.83481 11.9222 3.83481 13.3147C3.83481 14.8722 5.09741 16.1348 6.6549 16.1348Z" />
        </svg>
      </OutboundLink>
    </div>
  );
};
