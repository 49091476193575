import React, { useContext, useEffect, useState } from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import Event from "./event";
import Calendarbutton from "./mobile/calendarbutton";
import { CalendarContext } from "./calendar/CalendarContext";

const Layout = ({ children }) => {
  const memoizedCalendar = useContext(CalendarContext);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const mediaQuery = window.matchMedia("(max-width: 1199px)");
      setIsMobileView(mediaQuery.matches);

      const handleMediaQueryChange = (e) => {
        setIsMobileView(e.matches);
      };

      mediaQuery.addEventListener("change", handleMediaQueryChange);

      return () => {
        mediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }
  }, []);

  return isMobileView ? (
    <div className="flex flex-col space-y-4 xl:hidden">
      <div className="sticky top-0 z-50 mx-auto min-w-full">
        <Event />
        <Navbar />
      </div>
      <div className="mx-auto">
        {children}
        <div className="fixed bottom-10 left-0 xl:hidden">
          <Calendarbutton>{memoizedCalendar}</Calendarbutton>
        </div>
      </div>
      {/* <Footer /> */}
      <div />
    </div>
  ) : (
    <div className="hidden space-y-4 xl:flex xl:flex-col">
      <div className="sticky top-0 z-50">
        <Navbar />
      </div>
      <div className="container mx-auto flex flex-row gap-4">
        {children}
        <div className="relative flex flex-col space-y-4">
          <div className="sticky top-[77px]">
            <Event />
          </div>
          <div className="sticky top-[144px]">{memoizedCalendar}</div>
        </div>
      </div>
      <Footer />
      <div />
    </div>
  );
};

export default Layout;
