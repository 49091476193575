import React from "react";
import { Link } from "gatsby";
import myLogo from "./logo/logo.svg";

export default function Header() {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header className="">
      <Link to="/" onClick={handleScrollToTop}>
        <img src={myLogo} alt="lempiank.pl" />
      </Link>
    </header>
  );
}
