import React, { lazy, Suspense } from "react";
import { supabase } from "../components/supabase/supabase";
import { useAuth } from "../hooks/use-auth-hook";
import { useState } from "react";
import { Link } from "gatsby";
import ShowAvatar from "./user/ShowAvatar";
const Modal = lazy(() => import("./Modal"));

const Authenticated = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { user } = useAuth();

  const signOutSupabase = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error.message);
    }
  };

  const logout = () => {
    signOutSupabase()
      .then(() => {
        Object.keys(localStorage)
          .filter((key) => key.startsWith("likes_"))
          .forEach((key) => {
            localStorage.removeItem(key);
          });
      })
      .then(() => {
        window.location.replace(`/`);
      })
      .catch((error) => {
        console.error("Error while logging out", error);
      });
  };

  return (
    <>
      <div
        tabIndex={0}
        type="button"
        className="dropdown-end dropdown flex w-[160px] cursor-pointer select-none flex-row justify-end space-x-2"
        onClick={toggleDropdown}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            toggleDropdown();
          }
        }}
        role="button"
      >
        <ShowAvatar
          user={user}
          className="h-6 w-6 rounded-full"
          size={24}
          color="#FE93AE"
          spinnerclass={""}
        />
        <div className="truncate whitespace-nowrap font-lexend font-medium tracking-tighter text-logo dark:text-text_dark">
          {user?.user.user_metadata.full_name}
        </div>
        <div className="">
          <button className="rotate-180" title="Menu">
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 6.5L6 1.5L1 6.5"
                stroke="#959595"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {isOpen && (
            <div
              tabIndex={0}
              className="dropdown-content menu rounded-box mt-1 h-[120px] w-[146px] border border-[#D8D9D8] bg-[#FFFFFF] p-2 font-lexend text-sm font-medium text-[#0E0D0D] dark:bg-third_dark dark:text-text_dark"
              role="button"
            >
              <Link to="/ustawienia">
                <div
                  className="justify-left flex h-[50px] w-[130px] items-center rounded-xl hover:bg-[#F4F5F4] dark:hover:bg-fourth_dark"
                  role="button"
                >
                  <span className="ml-4">Ustawienia</span>
                </div>
              </Link>
              <button
                onClick={handleOpenModal}
                onKeyDown={handleOpenModal}
                className=""
                type="button"
              >
                <div
                  className="justify-left flex h-[50px] w-[130px] items-center rounded-xl hover:bg-[#F4F5F4] dark:hover:bg-fourth_dark"
                  role="button"
                >
                  <span className="ml-4">Wyloguj się</span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <Suspense>
        <Modal
          open={openModal}
          close={handleCloseModal}
          exec={() => {
            logout();
            setOpenModal(false);
          }}
        >
          <span className="mt-4 flex text-2xl font-semibold text-[#0E0D0D] dark:text-text_dark">
            Czy na pewno chcesz się
          </span>
          <span className="flex text-2xl font-semibold text-[#0E0D0D] dark:text-text_dark">
            wylogować?
          </span>
        </Modal>
      </Suspense>
    </>
  );
};
export default Authenticated;
