import React from "react";
import { useState } from "react";
import LoginModal from "./LoginModal";

export default function LoginButtons() {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);

    // Wywołaj funkcję trackEvent, aby wysłać zdarzenie do Google Analytics.
    if (typeof window.gtag === "function") {
      window.gtag("event", "LoginButtonClick", {
        event_category: "Button",
        event_action: "Login",
        value: 1,
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="">
      <div id="logowanie" className="flex flex-row font-lexend font-medium">
        <button
          type="button"
          title="Zaloguj się"
          aria-label="Logowanie"
          className="h-[48px] w-[134px] rounded-[44px]  bg-[#FEEDF0] text-[#FE93AE] hover:bg-main hover:text-white "
          onClick={handleOpenModal}
          onKeyDown={handleOpenModal}
        >
          Logowanie
        </button>
        {/* <button
          type="button"
          title="Zarejestruj się"
          aria-label="Rejestracja"
          className="bg-[#FEEDF0] rounded-[44px] hover:shadow-login_hover hover:bg-main text-[#FE93AE] hover:text-white w-[134px] h-[48px]"
        >
          Rejestracja
        </button> */}
      </div>
      <LoginModal open={openModal} close={handleCloseModal} />
    </div>
  );
}
