import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
export const Facebook = () => {
  const handleClick = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "facebook_link",
        event_label: "Strona na Facebooku - Lempiank",
      });
    }
  };

  return (
    <div>
      <OutboundLink
        href="https://facebook.com/lempiank"
        target="_blank"
        rel="noreferrer nofollow"
        onClick={handleClick}
        className="flex h-8  w-8 cursor-pointer items-center justify-center fill-gray_icons hover:fill-post_likes_font_color dark:fill-white dark:hover:fill-post_likes_font_color"
        title="Strona na Facebooku - Lempiank"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
          loading="lazy"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 11C0.5 16.1917 4.29167 20.5083 9.25 21.3833L9.30787 21.337C9.28858 21.3333 9.26929 21.3295 9.25 21.3257V13.9173H6.625V11.0007H9.25V8.66732C9.25 6.04232 10.9417 4.58398 13.3333 4.58398C14.0917 4.58398 14.9083 4.70065 15.6667 4.81732V7.50065H14.325C13.0417 7.50065 12.75 8.14232 12.75 8.95898V11.0007H15.55L15.0833 13.9173H12.75V21.3257C12.7307 21.3295 12.7114 21.3333 12.6921 21.337L12.75 21.3833C17.7083 20.5083 21.5 16.1917 21.5 11C21.5 5.225 16.775 0.5 11 0.5C5.225 0.5 0.5 5.225 0.5 11Z"
          />
        </svg>
      </OutboundLink>
    </div>
  );
};
