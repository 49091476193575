import * as React from "react";
import { Twitch } from "./socialbar/twitch";
import { Discord } from "./socialbar/discord";
import { Facebook } from "./socialbar/facebook";
import { Instagram } from "./socialbar/instagram";
import { Twitter } from "./socialbar/twitter";
import { Tiktok } from "./socialbar/tiktok";
import { Youtube } from "./socialbar/youtube";

const Socialbar = () => {
  return (
    <div className="flex flex-row items-center gap-x-2 xl:ml-auto xl:mr-0">
      <Facebook />
      <Instagram />
      <Youtube />
      <Discord />
      <Twitter />
      <Tiktok />
      <Twitch />
    </div>
  );
};
export default Socialbar;
