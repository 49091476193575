import { useState, useEffect } from "react";
import axios from "axios";

const TwitchAPI = ({ setIsStreamLive }) => {
  const clientId = process.env.GATSBY_TWITCH_API_CLIENTID;
  const username = process.env.GATSBY_TWITCH_API_USERNAME;
  const clientSecret = process.env.GATSBY_TWITCH_API_CLIENTSECRET;
  const [accessToken, setAccessToken] = useState(
    typeof sessionStorage !== "undefined"
      ? sessionStorage.getItem("twitch_access_token")
      : null
  );

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const response = await axios.post(
          "https://id.twitch.tv/oauth2/token",
          null,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            params: {
              client_id: clientId,
              client_secret: clientSecret,
              grant_type: "client_credentials",
            },
          }
        );
        setAccessToken(response.data.access_token);
        sessionStorage.setItem(
          "twitch_access_token",
          response.data.access_token
        );
      } catch (error) {
        console.log(error);
      }
    };

    if (!accessToken) {
      getAccessToken();
    }
  }, [accessToken, clientId, clientSecret]);

  useEffect(() => {
    const checkStream = async () => {
      if (!accessToken) {
        return;
      }
      try {
        const response = await axios.get(
          `https://api.twitch.tv/helix/streams?user_login=${username}`,
          {
            headers: {
              "Client-ID": `${clientId}`,
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setIsStreamLive(response.data.data.length > 0);
      } catch (error) {
        console.log(error);
      }
    };

    checkStream();
  }, [accessToken, clientId, username, setIsStreamLive]);

  return null;
};

export default TwitchAPI;
