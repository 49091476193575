module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GT-PHXZMD","GTM-NMJ9V4D"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"delayOnRouteUpdate":2000,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ania Łempicka","short_name":"Lempiank","description":"Blog kulinarny Ani Łempickiej - Lempiank, znajdziesz tutaj przepisy na każdą okazję. Ania jest finalistką 8 edycji Masterchefa, oraz aktywną streamerką na Twitch.tv.","start_url":"/","theme_color_in_head":false,"display":"minimal-ui","crossOrigin":"anonymous","icon":"src/images/logo_pianka.png","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"5a07bf4a19f8800f09481cb8f7528f13"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
