import React, { useContext } from "react";
import { ThemeContext } from "../components/Context/ThemeContext";

const ThemeToggler = (props) => {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <button
      aria-label="Zmień motyw"
      className={props.className}
      onClick={() => setTheme(theme === "light" ? "dark" : "light")}
    >
      <div className="">
        {theme === "dark" ? props.lightText : props.darkText}
      </div>
    </button>
  );
};

export default ThemeToggler;
