import React from "react";
import Header from "../components/header";
import Menu from "../components/menu";
import Socialbar from "../components/socialbar";
import LoginButtons from "../components/LoginButtons";
import Hamburger from "./mobile/hamburger";
import Authenticated from "./AuthenticatedMenu";
import { useAuth } from "../hooks/use-auth-hook";

const Navbar = () => {
  const { user, isAuthenticated } = useAuth();

  return (
    <nav className="border-b border-navline bg-white dark:border-third_dark dark:bg-second_dark">
      <div className="mx-auto hidden h-16 items-center xl:flex xl:w-[1200px] xl:flex-row xl:flex-nowrap xl:gap-x-6">
        <Header />
        <Menu />
        <Socialbar />
        {isAuthenticated ? <Authenticated user={user} /> : <LoginButtons />}
      </div>
      <div className="mx-auto flex h-[64px] w-[375px] flex-row items-center xl:hidden">
        <div className="ml-6">
          <Header />
        </div>
        <div className="ml-auto mr-6 mt-2">
          <Hamburger />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
