import React, { Suspense } from "react";
import Socialbar from "../socialbar";
import { useAuth } from "../../hooks/use-auth-hook";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ThemeToggler from "../../utils/ThemeToggler";
import HamburgerClosed from "../icons/HamburgerClosed.svg";
import HamburgerOpen from "../icons/HamburgerOpen.svg";
import TrybJasny from "../icons/TrybJasny.svg";
import TrybCiemny from "../icons/TrybCiemny.svg";
const Menu = React.lazy(() => import("../menu"));
const LoginModal = React.lazy(() => import("../LoginModal"));
const LoggedUser = React.lazy(() => import("./logged_mobile"));

function Hamburger() {
  let [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    if (!isOpen) setIsOpen(true);
  }
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const handleOpenModal = () => {
    setOpenLoginModal(true);
    if (typeof window.gtag === "function") {
      window.gtag("event", "LoginMobileButtonClick", {
        event_category: "Button",
        event_action: "LoginMobile",
        value: 1,
      });
    }
  };

  const handleCloseModal = () => {
    setOpenLoginModal(false);
  };

  const Setmenu = () => (
    <div className="h-8 w-8 fill-[#0E0D0D] dark:fill-white">
      <img
        src={isOpen ? HamburgerOpen : HamburgerClosed}
        className="self-center"
        alt="Hamburger"
      />
    </div>
  );

  const SunIcon = () => (
    <>
      <div className="flex flex-row items-center space-x-2">
        <span className="font-lexend">Tryb jasny</span>
        <img
          src={TrybJasny}
          className="h-7 w-7 self-center"
          alt="Tryb Jasny"
          loading="lazy"
        />
      </div>
    </>
  );

  const MoonIcon = () => (
    <>
      <div className="flex flex-row items-center space-x-2">
        <span className="font-lexend">Tryb Ciemny</span>
        <img
          src={TrybCiemny}
          className="h-7 w-7 self-center"
          alt="Tryb Ciemny"
          loading="lazy"
        />
      </div>
    </>
  );

  return (
    <div>
      <button
        type="button"
        title="Menu"
        aria-label="Menu"
        className=""
        onClick={() => setIsOpen(true)}
      >
        <Setmenu />
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" open={isOpen} onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-mobile_bg dark:bg-main_dark" />
          </Transition.Child>

          <div className="fixed inset-0 top-[125px] xl:top-[150px]">
            <div className="flex justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel as="div" className="xl:hidden">
                  <div className="flex flex-col items-center gap-y-10">
                    <Suspense>
                      <Menu onClick={closeModal} />
                      <ThemeToggler
                        className="self-center font-lexend text-mobile_menu_color dark:text-text_dark"
                        lightText={<SunIcon />}
                        darkText={<MoonIcon />}
                      />
                      <Socialbar />

                      <div className="self-center bg-[#D8D9D8] px-[128.5px] py-[1px]"></div>

                      {user ? (
                        <LoggedUser onClick={closeModal} />
                      ) : (
                        <div className="flex flex-col gap-y-2 font-lexend font-medium">
                          <button
                            type="button"
                            title="Zaloguj się"
                            aria-label="Logowanie"
                            className="h-[48px] w-[325px] rounded-[44px] bg-main text-white"
                            onClick={handleOpenModal}
                            onKeyDown={handleOpenModal}
                          >
                            Logowanie
                          </button>
                          <LoginModal
                            open={openLoginModal}
                            close={handleCloseModal}
                          />
                        </div>
                      )}
                    </Suspense>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
export default Hamburger;
