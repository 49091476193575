import "./src/styles/global.css";
import React from "react";
import ThemeContextProvider from "./src/components/Context/ThemeContext";
import AuthProvider from "./src/components/supabase/supabase";
import { CalendarProvider } from "./src/components/calendar/CalendarContext";
import Background from "./src/components/background";

export const wrapRootElement = ({ element }) => (
  <React.StrictMode>
    <ThemeContextProvider>
      <AuthProvider>
        <CalendarProvider>{element}</CalendarProvider>
      </AuthProvider>
    </ThemeContextProvider>
  </React.StrictMode>
);

export const wrapPageElement = ({ element, props }) => {
  props.shouldUpdateScroll = ({ routerProps: { location } }) => {
    if (location.pathname === props.location.pathname) {
      return false;
    }
    return true;
  };
  return <Background>{element}</Background>;
};
