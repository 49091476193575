import React, { useState, useEffect, Fragment, lazy } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { motion, useDragControls } from "framer-motion";

const breakpointSm = 1200;
const LazyLoginWithGoogle = lazy(() => import("./LogInWithGoogle"));
const LazyLoginWithTwitch = lazy(() => import("./LogInWithTwitch"));
const LazyLoginWithDiscord = lazy(() => import("./LogInWithDiscord"));
const LoginModal = ({ open, close }) => {
  const controls = useDragControls();
  function startDrag(event) {
    controls.start(event);
  }
  function onUpdate(latest) {
    if (latest.y > 50) {
      close();
    }
  }

  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return width < breakpointSm ? (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" onClose={close}>
        <div className="fixed inset-0 mt-20">
          <div className="flex justify-center text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel as="div" className="relative z-10">
                <div className="overflow-y-0 fixed inset-x-0 bottom-0 font-lexend font-medium">
                  <motion.div
                    drag="y"
                    dragPropagation
                    dragControls={controls}
                    onUpdate={onUpdate}
                    dragConstraints={{ top: 0, bottom: 500 }}
                    dragElastic={0}
                    dragTransition={{
                      bounceStiffness: 300,
                      bounceDamping: 10,
                    }}
                  >
                    <div className="relative mx-auto flex h-[270px] w-[99%] flex-col items-center rounded-t-xl border bg-white dark:bg-third_dark">
                      <div
                        onPointerDown={startDrag}
                        className="mt-4 rounded-lg bg-[#D8D9D8] px-4 py-0.5"
                      ></div>
                      <div className="mt-5 text-[#959595] dark:text-text_dark">
                        Witaj Pianeczko!
                      </div>
                      {LazyLoginWithGoogle && (
                        <div className="mt-4 flex flex-col space-y-3">
                          <LazyLoginWithGoogle />
                        </div>
                      )}
                      {LazyLoginWithTwitch && (
                        <div className="mt-4 flex flex-col space-y-3">
                          <LazyLoginWithTwitch />
                        </div>
                      )}
                      {LazyLoginWithDiscord && (
                        <div className="mt-4 flex flex-col space-y-3">
                          <LazyLoginWithDiscord />
                        </div>
                      )}
                    </div>
                  </motion.div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  ) : (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-[32%]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="border-1 h-[350px] w-[480px] max-w-md overflow-hidden rounded-3xl border border-[#D8D9D8] bg-white p-6 text-left align-middle shadow-xl outline-none transition-all hover:border-[#BBBCBB] focus:border-[#FE93AE] dark:bg-third_dark">
                <button
                  className="float-right cursor-pointer fill-[#0E0D0D] dark:fill-white"
                  onClick={close}
                  aria-label="Exit"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.9706 7.59925L7.91394 17.6559C7.57034 17.9995 7.00046 17.9995 6.65686 17.6559C6.31326 17.3123 6.31326 16.7424 6.65686 16.3988L16.7135 6.34217C17.0571 5.99857 17.627 5.99857 17.9706 6.34217C18.3142 6.68578 18.3142 7.25565 17.9706 7.59925Z" />
                    <path d="M17.9706 17.6578C17.627 18.0014 17.0571 18.0014 16.7135 17.6578L6.65686 7.6012C6.31326 7.25759 6.31326 6.68772 6.65686 6.34412C7.00046 6.00052 7.57034 6.00052 7.91394 6.34412L17.9706 16.4007C18.3142 16.7443 18.3142 17.3142 17.9706 17.6578Z" />
                  </svg>
                </button>
                <Dialog.Title
                  as="h3"
                  className="font-login_title mt-8 text-center font-lexend text-2xl font-semibold dark:text-text_dark"
                >
                  Witaj Pianeczko!
                </Dialog.Title>
                <div className="font-lexend">
                  <div className="space-y-4  font-medium">
                    {LazyLoginWithGoogle && (
                      <div className="mt-8 flex justify-center">
                        <LazyLoginWithGoogle />
                      </div>
                    )}
                    {LazyLoginWithTwitch && (
                      <div className="flex justify-center">
                        <LazyLoginWithTwitch />
                      </div>
                    )}
                    {LazyLoginWithDiscord && (
                      <div className="flex justify-center">
                        <LazyLoginWithDiscord />
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoginModal;
