import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
export const Twitch = () => {
  const handleClick = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "twitch_link",
        event_label: "Kanał na Twitchu - Lempiank",
      });
    }
  };
  return (
    <div>
      <OutboundLink
        href="https://www.twitch.tv/lempiank/"
        target="_blank"
        rel="noreferrer nofollow"
        onClick={handleClick}
        className="flex h-8 w-8 cursor-pointer items-center justify-center fill-gray_icons hover:fill-post_likes_font_color dark:fill-white dark:hover:fill-post_likes_font_color"
        title="Kanał na Twitchu - Lempiank"
      >
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill=""
          xmlns="http://www.w3.org/2000/svg"
          loading="lazy"
        >
          <path d="M3.65917 0.0253906L0.201172 3.23082V14.7704H4.35077V17.9758L7.80876 14.7704H10.5752L16.7996 9.00059V0.0253906H3.65917ZM15.4164 8.3595L12.65 10.9238H9.88356L7.46296 13.1676V10.9238H4.35077V1.30756H15.4164V8.3595Z" />
          <path d="M13.3422 3.55078H11.959V7.39729H13.3422V3.55078Z" />
          <path d="M9.53945 3.55078H8.15625V7.39729H9.53945V3.55078Z" />
        </svg>
      </OutboundLink>
    </div>
  );
};
