import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
export const Youtube = () => {
  const handleClick = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "youtube_link",
        event_label: "Profil na Youtube - Lempiank",
      });
    }
  };
  return (
    <div>
      <OutboundLink
        href="https://youtube.com/@anialempiank"
        target="_blank"
        rel="noreferrer nofollow"
        onClick={handleClick}
        className="flex h-8 w-8 items-center justify-center stroke-gray_icons hover:stroke-post_likes_font_color dark:stroke-white dark:hover:stroke-post_likes_font_color"
        title="Konto na Youtube - Lempiank"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          loading="lazy"
        >
          <path
            d="M14 12L10.5 14V10L14 12Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 12.7074V11.2924C2 8.39739 2 6.94939 2.905 6.01839C3.811 5.08639 5.237 5.04639 8.088 4.96539C9.438 4.92739 10.818 4.90039 12 4.90039C13.181 4.90039 14.561 4.92739 15.912 4.96539C18.763 5.04639 20.189 5.08639 21.094 6.01839C22 6.94939 22 8.39839 22 11.2924V12.7074C22 15.6034 22 17.0504 21.095 17.9824C20.189 18.9134 18.764 18.9544 15.912 19.0344C14.562 19.0734 13.182 19.1004 12 19.1004C10.6958 19.0965 9.39163 19.0745 8.088 19.0344C5.237 18.9544 3.811 18.9144 2.905 17.9824C2 17.0504 2 15.6024 2 12.7084V12.7074Z"
            strokeWidth="1.5"
          />
        </svg>
      </OutboundLink>
    </div>
  );
};
