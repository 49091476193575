import * as React from "react";
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { motion, useDragControls, useAnimation } from "framer-motion";

const Calendarbutton = ({ children }) => {
  const controls = useDragControls();
  const animationControls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      // Sprawdź, czy sessionStorage i window są dostępne
      if (
        typeof sessionStorage === "undefined" ||
        typeof window === "undefined"
      ) {
        return;
      }

      const animationRendered = sessionStorage.getItem("animationRendered");
      const sessionId = sessionStorage.getItem("sessionId");

      // Sprawdź, czy animacja została już wykonana w tej sesji
      if (animationRendered && sessionId === window.name) {
        return;
      }

      // Wykonaj animację
      await animationControls.start({
        scale: 1.2,
        transition: { duration: 0.7 },
      });
      await animationControls.start({
        scale: 1.0,
        transition: { duration: 0.7 },
      });
      await animationControls.start({
        scale: 1.2,
        transition: { duration: 0.7 },
      });
      await animationControls.start({
        scale: 1.0,
        transition: { duration: 0.7 },
      });
      await animationControls.start({
        scale: 1.2,
        transition: { duration: 0.7 },
      });
      await animationControls.start({
        scale: 1.0,
        transition: { duration: 0.7 },
      });

      // Zapisz informacje o animacji i sesji
      sessionStorage.setItem("animationRendered", true);
      sessionStorage.setItem("sessionId", window.name);
    };

    sequence();
  }, [animationControls]);

  function startDrag(event) {
    controls.start(event);
  }
  function onUpdate(latest) {
    if (latest.y > 150) {
      setIsOpen(false);
    }
  }

  function handleCalendarOpen() {
    // Wysyłanie zdarzenia do Google Analytics
    if (typeof window.gtag === "function") {
      window.gtag("event", "calendar_open_mobile", {
        event_category: "Calendar",
        event_label: "Open",
      });
    }
    openModal();
  }

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  return (
    <div>
      <motion.button
        animate={animationControls}
        transition={{ duration: 0.7 }}
        onClick={handleCalendarOpen}
        aria-label="Kalendarz"
      >
        <div className="flex h-[40px] w-[44px] rounded-r-xl border-b-2 border-r-2 border-t-2 border-[#FE93AE]/[.5] bg-white  dark:bg-third_dark">
          <div className="mx-auto self-center">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=""
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.667 1.66699C11.2193 1.66699 11.667 2.11471 11.667 2.66699V6.66699C11.667 7.21928 11.2193 7.66699 10.667 7.66699C10.1147 7.66699 9.66699 7.21928 9.66699 6.66699V2.66699C9.66699 2.11471 10.1147 1.66699 10.667 1.66699Z"
                fill="#FE93AE"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.333 1.66699C21.8853 1.66699 22.333 2.11471 22.333 2.66699V6.66699C22.333 7.21928 21.8853 7.66699 21.333 7.66699C20.7807 7.66699 20.333 7.21928 20.333 6.66699V2.66699C20.333 2.11471 20.7807 1.66699 21.333 1.66699Z"
                fill="#FE93AE"
              />
              <path
                opacity="0.4"
                d="M28.6663 11.1603V22.8403C28.6663 23.0537 28.653 23.267 28.6397 23.467H3.35967C3.34634 23.267 3.33301 23.0537 3.33301 22.8403V11.1603C3.33301 7.57366 6.23967 4.66699 9.82634 4.66699H22.173C25.7597 4.66699 28.6663 7.57366 28.6663 11.1603Z"
                fill="#FE93AE"
              />
              <path
                d="M28.6404 23.4668C28.3204 26.7601 25.547 29.3335 22.1737 29.3335H9.82702C6.45368 29.3335 3.68035 26.7601 3.36035 23.4668H28.6404Z"
                fill="#FE93AE"
              />
              <path
                d="M18.0403 15.4927C18.6403 15.0794 19.0137 14.466 19.0137 13.6394C19.0137 11.906 17.627 11.0127 16.0003 11.0127C14.3737 11.0127 12.9737 11.906 12.9737 13.6394C12.9737 14.466 13.3603 15.0927 13.947 15.4927C13.1337 15.9727 12.667 16.746 12.667 17.6527C12.667 19.306 13.9337 20.3327 16.0003 20.3327C18.0537 20.3327 19.3337 19.306 19.3337 17.6527C19.3337 16.746 18.867 15.9594 18.0403 15.4927ZM16.0003 12.666C16.6937 12.666 17.2003 13.0527 17.2003 13.7194C17.2003 14.3727 16.6937 14.786 16.0003 14.786C15.307 14.786 14.8003 14.3727 14.8003 13.7194C14.8003 13.0527 15.307 12.666 16.0003 12.666ZM16.0003 18.666C15.1203 18.666 14.4803 18.226 14.4803 17.426C14.4803 16.626 15.1203 16.1994 16.0003 16.1994C16.8803 16.1994 17.5203 16.6394 17.5203 17.426C17.5203 18.226 16.8803 18.666 16.0003 18.666Z"
                fill="#FE93AE"
              />
            </svg>
          </div>
        </div>
      </motion.button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={closeModal}>
          <div className="fixed inset-0 mt-20">
            <div className="flex justify-center text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel as="div" className="xl:hidden">
                  <div className="overflow-y-0 fixed inset-x-0 bottom-0 font-lexend font-medium">
                    <motion.div
                      drag="y"
                      dragPropagation
                      dragControls={controls}
                      onUpdate={onUpdate}
                      dragConstraints={{ top: 0, bottom: 534 }}
                      dragElastic={0}
                      dragTransition={{
                        bounceStiffness: 300,
                        bounceDamping: 10,
                      }}
                    >
                      <div className="relative mx-auto flex h-[500px] w-[99%] flex-col items-center rounded-t-xl border bg-white dark:bg-third_dark">
                        <div className="" onPointerDown={startDrag}></div>

                        {children}
                      </div>
                    </motion.div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
export default Calendarbutton;
