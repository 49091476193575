import * as React from "react";
import { Link } from "gatsby";

const Menu = ({ onClick }) => {
  return (
    <div className="">
      <ul className="flex flex-col space-y-8 font-lexend text-mobile_menu_color dark:text-text_dark xl:flex-row xl:space-x-6 xl:space-y-0 xl:font-medium xl:text-gray_icons">
        <li className="hover:text-main">
          <Link to="/o-mnie" activeClassName="text-main" onClick={onClick}>
            O mnie
          </Link>
        </li>
        <li className="hover:text-main">
          <Link to="/sprzet" activeClassName="text-main" onClick={onClick}>
            Sprzęt
          </Link>
        </li>
        <li className="text-main">
          <a
            href="https://donate.stripe.com/dR69DD4cs7MY6zK9AA?locale=pl"
            onClick={onClick}
            rel="nofollow"
          >
            Donacja
          </a>
        </li>
        <li className="hover:text-main">
          <Link
            to="/polityka-prywatnosci"
            activeClassName="text-main"
            className="xl:hidden"
            onClick={onClick}
          >
            Polityka prywatności
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
