import React, { useEffect, useState } from "react";
import { supabase } from "../supabase/supabase";
import { ClipLoader } from "react-spinners";

const ShowAvatar = ({
  user,
  avatarSource,
  className,
  size,
  color,
  spinnerclass,
}) => {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const storage = supabase.storage.from("public");

    const getAvatarUrl = async () => {
      if (avatarSource) {
        const reader = new FileReader();
        reader.readAsDataURL(avatarSource);
        reader.onloadend = () => {
          setAvatarUrl(reader.result);
          setIsLoading(false);
        };
      } else if (user?.user?.id) {
        const { data: [avatar] = [] } = await storage.list(`${user.user.id}/`);
        if (avatar && avatar.name === "avatar.jpg") {
          const currentTimestamp = new Date().getTime();
          const { data } = await storage.getPublicUrl(
            `${user?.user.id}/avatar.jpg?timestamp=${currentTimestamp}`
          );
          if (data.publicUrl) {
            setAvatarUrl(data.publicUrl);
            setIsLoading(false);
            return;
          }
        }
        setAvatarUrl(user?.user?.user_metadata?.avatar_url);
        setIsLoading(false);
      }
    };

    getAvatarUrl();

    return () => {};
  }, [user, avatarSource]);

  if (isLoading) {
    return <ClipLoader size={size} color={color} className={spinnerclass} />;
  }

  return <img src={avatarUrl} alt="Avatar" className={className} />;
};

export default ShowAvatar;
