import React, { useState } from "react";
import TwitchAPI from "./twitch/TwitchAPI";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Event = () => {
  const [isStreamLive, setIsStreamLive] = useState(false);
  const handleClick = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "twitch_link_event",
        event_label: "Twitch Event - Lempiank",
      });
    }
  };
  return (
    <>
      <TwitchAPI
        isStreamLive={isStreamLive}
        setIsStreamLive={setIsStreamLive}
      />
      <OutboundLink
        href="https://www.twitch.tv/lempiank/"
        target="_blank"
        rel="noreferrer nofollow"
        onClick={handleClick}
      >
        <div className="flex h-[30px] min-w-[99%] items-center justify-center space-x-2 bg-event font-lexend font-normal shadow xl:h-[52px] xl:w-[360px] xl:rounded-xl">
          {isStreamLive ? (
            <>
              <div className="flex flex-row">
                <span className="text-white dark:text-text_dark">
                  Lempiank prowadzi stream!
                </span>
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ffffff"
                  className="ml-1 mt-1"
                >
                  <title>Twitch.tv | lempiank.pl</title>
                  <path d="M3.857 0 1 2.857v10.286h3.429V16l2.857-2.857H9.57L14.714 8V0H3.857zm9.714 7.429-2.285 2.285H9l-2 2v-2H4.429V1.143h9.142v6.286z" />
                  <path d="M11.857 3.143h-1.143V6.57h1.143V3.143zm-3.143 0H7.571V6.57h1.143V3.143z" />
                </svg>

                <div className={`live-indicator live`} />
              </div>
            </>
          ) : (
            <div className="flex flex-row">
              <span className="text-white dark:text-text_dark">
                Odwiedź mnie na twitchu!
              </span>
              <svg
                width="18px"
                height="18px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffffff"
                className="ml-1 mt-1"
              >
                <title>Twitch.tv | lempiank.pl</title>
                <path d="M3.857 0 1 2.857v10.286h3.429V16l2.857-2.857H9.57L14.714 8V0H3.857zm9.714 7.429-2.285 2.285H9l-2 2v-2H4.429V1.143h9.142v6.286z" />
                <path d="M11.857 3.143h-1.143V6.57h1.143V3.143zm-3.143 0H7.571V6.57h1.143V3.143z" />
              </svg>
            </div>
          )}
        </div>
      </OutboundLink>
    </>
  );
};
export default Event;
